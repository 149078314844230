import React, { useState, useEffect } from "react";
import Header from "./assets/Header.png";
import "./Style.css";
import useAxios from "axios-hooks";
import { Toaster, toast } from "sonner";
import { useNavigate } from "react-router-dom";

const uploadFileToS3 = async (url, file) => {
  console.log("the url", url);
  console.log("the file", file);
  try {
    const response = await fetch(url, {
      method: "PUT",
      body: file,
    });

    if (!response.ok) {
      throw new Error("Failed to upload file");
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

const StepForm = () => {
  const [{ loading: signingIn }, registerUser] = useAxios(
    {
      url: "https://24imx07i10.execute-api.eu-west-1.amazonaws.com/v1/user/register",
      method: "POST",
    },
    { manual: true }
  );

  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    region: "",
    educationLevel: "",
    lastSchoolAttended: "",
    educationFieldOfStudy: "",
    educationMajor: "",
    lastEmployerName: "",
    lastEmploymentDate: new Date(),
    ghanaCardNumber: "",
    ghanaCardDocument: null,
    votersIdDocument: null,
    cvDocument: null,
    educationalCertificateDocument: null,
  });

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      cvDocument,
      ghanaCardDocument,
      votersIdDocument,
      educationalCertificateDocument,
      ...payload
    } = formData;

    try {
      const response = await registerUser({
        data: payload,
      });

      if (response.data) {
        const fileUploads = response.data.data.fileUploads.reduce(
          (acc, item) => {
            acc[item.name] = item.url;
            return acc;
          },
          {}
        );

        // Create a map of document types to file inputs
        const documents = {
          "ghana-card": ghanaCardDocument,
          "voters-id": votersIdDocument,
          cv: cvDocument,
          "educational-certificate": educationalCertificateDocument,
        };

        // Upload each document using its corresponding pre-signed URL
        const uploadPromises = Object.entries(documents).map(
          async ([documentType, file]) => {
            const uploadUrl = fileUploads[documentType];
            console.log("the file and upload url", file, uploadUrl);
            if (file && uploadUrl) {
              await uploadFileToS3(uploadUrl, file);
            }
          }
        );

        // Wait for all uploads to complete
        await Promise.all(uploadPromises);

        toast.success("Account successfully created, please login");
        navigate("/");
      }
    } catch (e) {
      toast.error(e?.message || "Failed to create account");
    }
  };

  const handleUpload = (documentType) => (event) => {
    console.log("Collecting image", documentType, event);
    const file = event.target.files[0];
    setFormData({ ...formData, [documentType]: file });
  };

  const REGIONS = [
    "Ahafo",
    "Ashanti",
    "Bono East",
    "Brong Ahafo",
    "Central",
    "Eastern",
    "Greater Accra",
    "North East",
    "Northern",
    "Oti",
    "Savannah",
    "Upper East",
    "Upper West",
    "Volta",
    "Western",
    "Western North",
  ];

  const LEVEL = [
    "Primary",
    "Junior High School",
    "Senior High School",
    "Associate Degree",
    "Bachelor's",
    "Master's",
    "PhD",
  ];

  return (
    <div className="container">
      <Toaster />
      <img src={Header} alt="Header" />
      {step === 1 && (
        <div className="wrapper">
          <div className="inputBox">
            <div className="inputWrapper">
              <label>First Name</label>
              <input
                name="firstName"
                value={formData.firstName}
                type="text"
                placeholder="Your First Name"
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="inputBox">
            <div className="inputWrapper">
              <label>Last Name</label>
              <input
                name="lastName"
                value={formData.lastName}
                type="text"
                placeholder="Your Last Name"
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="inputBox">
            <div className="inputWrapper">
              <label>Phone</label>
              <input
                name="phone"
                type="tel"
                value={formData.phone}
                placeholder="Your Phone number"
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="inputBox">
            <div className="inputWrapper">
              <label>Email</label>
              <input
                name="email"
                value={formData.email}
                type="email"
                placeholder="Your Email"
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="inputBox">
            <div className="inputWrapper">
              <label>Password</label>
              <input
                name="password"
                value={formData.password}
                type="password"
                placeholder="Your Password"
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="inputBox">
            <div className="inputWrapper">
              <label>Region</label>
              <select
                name="region"
                value={formData.region}
                onChange={handleChange}
                required
              >
                {REGIONS.map((r) => (
                  <option key={r} value={r}>
                    {r}
                  </option>
                ))}
                <option value="none">None</option>
              </select>
            </div>
          </div>

          <button
            type="button"
            style={{ width: "45%" }}
            className="button button-primary"
            onClick={nextStep}
          >
            Next
          </button>
        </div>
      )}

      {step === 2 && (
        <div className="wrapper">
          <div className="inputsWrapper">
            <div className="inputBox">
              <div className="inputWrapper">
                <label>Highest Level of Education</label>
                <select
                  name="educationLevel"
                  value={formData.educationLevel}
                  onChange={handleChange}
                  required
                >
                  {LEVEL.map((r) => (
                    <option key={r} value={r}>
                      {r}
                    </option>
                  ))}
                  <option value="none">None</option>
                </select>
              </div>
            </div>

            <div className="inputBox">
              <div className="inputWrapper">
                <label>Last school attended</label>
                <input
                  name="lastSchoolAttended"
                  type="text"
                  value={formData.lastSchoolAttended}
                  placeholder="Your last school attended"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="inputBox">
              <div className="inputWrapper">
                <label>Major</label>
                <input
                  name="educationMajor"
                  type="text"
                  value={formData.educationMajor}
                  placeholder="Education Major"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="inputBox">
              <div className="inputWrapper">
                <label>Field</label>
                <input
                  name="educationFieldOfStudy"
                  type="text"
                  value={formData.educationFieldOfStudy}
                  placeholder="Field of Study"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="inputBox">
              <div className="inputWrapper">
                <label>Last Employer</label>
                <input
                  name="lastEmployerName"
                  type="text"
                  value={formData.lastEmployerName}
                  placeholder="Name of your last employer"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="inputBox">
              <div className="inputWrapper">
                <label htmlFor="lastEmploymentDate">Last Employment Date</label>
                <input
                  id="lastEmploymentDate"
                  name="lastEmploymentDate"
                  type="date"
                  value={formData.lastEmploymentDate}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div
              className="buttonGroup"
              style={{ justifyContent: "space-between", display: "flex" }}
            >
              <button
                type="button"
                style={{ width: "45%" }}
                className="button button-secondary"
                onClick={prevStep}
              >
                Previous
              </button>

              <button
                type="button"
                style={{ width: "45%" }}
                className="button button-primary"
                onClick={nextStep}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="wrapper">
          <div className="inputsWrapper">
            <div className="inputBox">
              <div className="inputWrapper">
                <label>Ghana Card Number</label>
                <input
                  name="ghanaCardNumber"
                  type="text"
                  value={formData.ghanaCardNumber}
                  placeholder="GHA-000000000-0"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="inputBox">
              <div className="inputWrapper">
                <label htmlFor="ghanaCardDocument">Ghana Card</label>
                <input
                  id="ghanaCardDocument"
                  name="ghanaCardDocument"
                  type="file"
                  onChange={handleUpload("ghanaCardDocument")}
                />
              </div>
            </div>

            <div className="inputBox">
              <div className="inputWrapper">
                <label htmlFor="votersIdDocument">Voter's Id Card</label>
                <input
                  id="votersIdDocument"
                  name="votersIdDocument"
                  type="file"
                  onChange={handleUpload("votersIdDocument")}
                />
              </div>
            </div>

            <div className="inputBox">
              <div className="inputWrapper">
                <label htmlFor="cvDocument">Upload CV</label>
                <input
                  id="cvDocument"
                  name="cvDocument"
                  type="file"
                  onChange={handleUpload("cvDocument")}
                />
              </div>
            </div>

            <div className="inputBox">
              <div className="inputWrapper">
                <label htmlFor="educationalCertificateDocument">
                  School Certificate
                </label>
                <input
                  id="educationalCertificateDocument"
                  name="educationalCertificateDocument"
                  type="file"
                  onChange={handleUpload("educationalCertificateDocument")}
                />
              </div>
            </div>

            <div
              className="buttonGroup"
              style={{ justifyContent: "space-between", display: "flex" }}
            >
              <button
                type="button"
                style={{ width: "45%" }}
                className="button button-secondary"
                onClick={prevStep}
              >
                Previous
              </button>

              <button
                type="button"
                style={{ width: "45%" }}
                className="button button-primary"
                onClick={handleSubmit}
              >
                {signingIn ? "Registering..." : "Register"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StepForm;
