import React, { useState } from "react";
import "./Style.css";
import Header from "./assets/Header.png";
import { useNavigate } from "react-router-dom";
import useAxios from "axios-hooks";
import { Toaster, toast } from "sonner";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [{ data: loginRes, loading: loggingIn }, loginUser] = useAxios(
    {
      url: "https://24imx07i10.execute-api.eu-west-1.amazonaws.com/v1/user/login",
      method: "POST",
    },
    { manual: true }
  );

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await loginUser({ data: { email, password } }).then(() => {
        toast.success("Successfully logged in!");
        localStorage.setItem("user-data", JSON.stringify(loginRes.data.user));
        navigate("/HomePage");
      });
    } catch (error) {
      console.log("Login error:", error);
      toast.error("Login failed. Please check your credentials and try again.");
    }
  };

  return (
    <div className="container">
      <Toaster />

      <img src={Header} alt="Header" />

      <div className="wrapper">
        <form onSubmit={handleLogin}>
          <h1>Welcome! Amazing Job Opportunities Await!</h1>

          <div className="inputBox">
            <div className="inputWrapper">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                placeholder="Your Email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
          </div>

          <div className="inputBox">
            <div className="inputWrapper">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                placeholder="Your Password"
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </div>
          </div>

          <div className="buttonGroup">
            <button
              type="submit"
              className="button button-primary"
              disabled={loggingIn}
            >
              {loggingIn ? "Logging in..." : "Login"}
            </button>
            <button
              type="button"
              className="button button-secondary"
              onClick={() => navigate("/StepForm")}
            >
              Signup
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
