import React, { useState } from "react";
import "./Style.css";
import Pending from "./assets/Pending.png";
import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem("user-data");
  const userData = JSON.parse(user);

  const formatDate = (date) => {
    const options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const formatter = new Intl.DateTimeFormat("en-GB", options);
    const parts = formatter.formatToParts(date);

    let day = parts.find((part) => part.type === "day").value;
    const month = parts.find((part) => part.type === "month").value;
    const year = parts.find((part) => part.type === "year").value;
    const weekday = parts.find((part) => part.type === "weekday").value;

    const suffix = (day) => {
      const j = day % 10;
      const k = day % 100;
      if (j === 1 && k !== 11) {
        return day + "st";
      }
      if (j === 2 && k !== 12) {
        return day + "nd";
      }
      if (j === 3 && k !== 13) {
        return day + "rd";
      }
      return day + "th";
    };

    return `${weekday}, ${suffix(day)} ${month} ${year}`;
  };

  const currentDate = formatDate(new Date());

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <div className="welcome-container">
          <h1 className="welcome-message">Welcome {userData.firstName}</h1>
          <p className="current-date" style={{ color: "grey" }}>
            {currentDate}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <div className="user-icon" onClick={() => navigate("/Profile")}>
            <FaUser size={24} />
          </div>
        </div>
      </header>
      <main className="main-content">
        <img src={Pending} alt="Status" className="status-image" />
        <h1 className="welcome-message">
          Your Job Application is Currently Pending
        </h1>
        <p>Kindly hold on while we find the right job for you.</p>
        <p>You wil be notified as soon as you are hired.</p>
      </main>
    </div>
  );
};

export default HomePage;
