import Login from "./components/Login";
import StepForm from "./components/StepForm";
import HomePage from "./components/HomePage";
import Profile from "./components/Profile";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/HomePage" element={<HomePage />} />
          <Route exact path="/StepForm" element={<StepForm />} />
          <Route exact path="/Profile" element={<Profile />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
