import React, { useState, useEffect } from "react";
import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useAxios from "axios-hooks";
import "./Style.css";

const Profile = () => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    phone: "",
    email: "",
  });

  const [{ data, loading, error }, fetchUserData] = useAxios(
    "https://24imx07i10.execute-api.eu-west-1.amazonaws.com/v1/user/profile",
    { useCache: false }
  );

  const [{ loading: updating, error: updateError }, updateUserData] = useAxios(
    {
      url: "https://24imx07i10.execute-api.eu-west-1.amazonaws.com/v1/user/profile",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    },
    { manual: true }
  );

  // Initialize state from local storage
  useEffect(() => {
    const storedUser = localStorage.getItem("user-data");
    if (storedUser) {
      setUserData(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    if (data) {
      setUserData(data);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      console.error("Error fetching user data:", error);
    }
  }, [error]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      await updateUserData({ data: userData });
      setIsEditing(false);
      alert("Profile updated successfully!");
      localStorage.setItem("user-data", JSON.stringify(userData));
    } catch (error) {
      console.error("Error updating user data:", error);
      alert("Failed to update profile.");
    }
  };

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <div className="welcome-container">
          <h1 className="welcome-message">Welcome {userData.firstName}</h1>
          <p className="current-date" style={{ color: "grey" }}>
            {new Intl.DateTimeFormat("en-GB", {
              weekday: "long",
              day: "numeric",
              month: "long",
              year: "numeric",
            }).format(new Date())}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <div className="user-icon" onClick={() => navigate("/HomePage")}>
            <FaUser size={24} />
          </div>
        </div>
      </header>
      <main className="profile-main">
        <div>
          {isEditing ? (
            <button className="button button-primary" onClick={handleSave}>
              Save
            </button>
          ) : (
            <button
              className="button button-primary"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </button>
          )}
        </div>

        <div className="profile-form">
          {Object.keys(userData)
            .filter(
              (key) => !["_id", "__v", "createdAt", "updatedAt"].includes(key)
            ) // Filter out unwanted keys
            .map((key) => (
              <div className="dataBox" key={key}>
                <div className="inputWrapper">
                  <label htmlFor={key}>{key.replace(/([A-Z])/g, " $1")}</label>
                  <input
                    id={key}
                    name={key}
                    type={key.includes("password") ? "password" : "text"}
                    value={userData[key] || ""}
                    onChange={handleChange}
                    disabled={!isEditing}
                  />
                </div>
              </div>
            ))}
        </div>
      </main>
    </div>
  );
};

export default Profile;
